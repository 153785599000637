import React, { useState, useRef, useEffect } from "react"
import DefaultAvatar from "../assets/images/sample-avatar.svg"
import EditIcon from "../assets/images/edit-icon.svg"
import DeleteIcon from "../assets/images/delete-icon.svg"
import UploadIcon from "../assets/images/upload-icon.svg"
import CrossIcon from "../assets/images/cross-icon.svg"
import TickIcon from "../assets/images/tick-icon.svg"
import { ADD_MEMBER, DELETE_MEMBER, UPDATE_MEMBER } from "../gql/team"
import { useMutation } from "@apollo/react-hooks"
import Throbber from "./throbber"
import useS3 from "../hooks/useS3"
import { Trans, useTranslation } from "react-i18next"

const TeamCard = ({
  email,
  name,
  image,
  id,
  handleOnRefetch,
  isAddCard,
  onClose,
  userData,
}) => {
  const { t } = useTranslation()
  const [editMode, setEditMode] = useState(false)
  const [deleteMode, setDeleteMode] = useState(false)
  const nameRef = useRef(null)
  const emailRef = useRef(null)
  const [nameData, setNameData] = useState("")
  const [emailData, setEmailData] = useState("")
  const [updateMember, { loading: loadingEdit }] = useMutation(UPDATE_MEMBER)
  const [deleteMember, { loading: loadingDelete }] = useMutation(DELETE_MEMBER)
  const [addMember, { loading: loadingAdd }] = useMutation(ADD_MEMBER)
  const { uploadToS3, loading: loadingMediaUpload } = useS3()
  const [selectedFile, setSelectedFile] = useState(null)

  const handleAddMember = async (name, email, image) => {
    try {
      let variables = {
        name,
        email,
        image,
      }
      if (selectedFile) {
        const file = selectedFile[0]
        const s3UploadResponse = await uploadToS3(file)
        variables.image = s3UploadResponse
      }

      addMember({
        variables: variables,
        update: () => {
          handleOnRefetch()
          onClose()
          setSelectedFile(null)
        },
      })
    } catch (e) {
      console.log(e, "ERROR WHILE ADDING MEMEBR")
    }
  }

  const handleSubmitEdit = async (name, email) => {
    try {
      let variables = {
        id: id,
        name,
        email,
      }
      if (selectedFile) {
        const file = selectedFile[0]
        const s3UploadResponse = await uploadToS3(file)
        variables["image"] = s3UploadResponse
      }
      updateMember({
        variables: variables,
        update: () => {
          setEditMode(false)
          handleOnRefetch()
          setSelectedFile(null)
        },
      })
    } catch (e) {
      console.log(e, "ERROR WHILE UPDATING MEMEBR")
    }
  }

  const handleDelete = () => {
    deleteMember({
      variables: {
        id: id,
      },
      update: () => {
        setDeleteMode(false)
        handleOnRefetch()
      },
    })
  }

  const handleOnEditPress = () => {
    setEditMode(true)
  }

  const handleOnDeletePress = () => {
    setDeleteMode(true)
  }

  const handleOnCancel = () => {
    setSelectedFile(null)
    if (editMode) {
      setNameData(name)
      setEmailData(email)
      setEditMode(false)
    } else if (deleteMode) {
      setDeleteMode(false)
    } else {
      onClose()
    }
  }

  const handleOnSave = () => {
    if (editMode) {
      if (nameData.length) {
        handleSubmitEdit(nameData, emailData)
      }
    } else if (deleteMode) {
      handleDelete(id)
    } else if (isAddCard) {
      handleAddMember(nameData, emailData, null)
    }
  }

  useEffect(() => {
    if (email) {
      setEmailData(email)
    }
  }, [email])

  useEffect(() => {
    if (name) {
      setNameData(name)
    }
  }, [name])

  const loading =
    loadingDelete || loadingEdit || loadingAdd || loadingMediaUpload

  return (
    <div className="team-box">
      <div className="action-container">
        {!editMode && !deleteMode && !loading && !isAddCard ? (
          <>
            <img
              className="action-icon"
              onClick={handleOnEditPress}
              src={EditIcon}
              alt="Edit"
            />
            <img
              className="action-icon"
              onClick={handleOnDeletePress}
              src={DeleteIcon}
              alt="Delete"
            />
          </>
        ) : !loading ? (
          <>
            <img
              className="edit-actions"
              onClick={handleOnCancel}
              src={CrossIcon}
              alt="Close"
            />
            <img
              className="edit-actions"
              onClick={handleOnSave}
              src={TickIcon}
              alt="Save"
            />
          </>
        ) : loading ? (
          <Throbber relative alignRight />
        ) : (
          <></>
        )}
      </div>
      <div className="each-container">
        <div>
          <img
            className="avatar-box"
            src={
              selectedFile
                ? URL.createObjectURL(selectedFile[0]) // Use selectedFile URL if available
                : image && image.path
                ? image.path // Use image URL if available
                : DefaultAvatar // Fallback to DefaultAvatar
            }
            style={{ height: 120, width: 140, objectFit: "cover" }}
            alt="Avatar"
          />
        </div>
        {editMode || isAddCard ? (
          <div style={{ position: "absolute", left: 5 }}>
            <label htmlFor={`file-input-${id}`} className="upload-icon">
              <img className="upload-icon" src={UploadIcon} alt="Upload" />
            </label>
            <input
              id={`file-input-${id}`}
              type="file"
              accept="image/*"
              onChange={e => setSelectedFile(e.target.files)}
              style={{ display: "none" }}
            />
          </div>
        ) : (
          <></>
        )}
      </div>
      <div className="each-container">
        {!editMode && !isAddCard ? <p className="name-text">{name}</p> : null}
        {editMode || isAddCard ? (
          <input
            type="text"
            className="email-text"
            placeholder={t("team.name_placeholder")}
            ref={nameRef}
            value={nameData}
            onChange={e => setNameData(e.target.value)}
          />
        ) : null}
      </div>
      <div className="each-container">
        {!editMode && !isAddCard ? (
          <p className="email-text">{emailData}</p>
        ) : null}
        {editMode || isAddCard ? (
          <input
            type="email"
            className="email-text"
            placeholder={t("login.email_label")}
            ref={emailRef}
            value={emailData}
            onChange={e => setEmailData(e.target.value)}
          />
        ) : null}
      </div>
      {userData && userData.email && userData.email === email ? (
        <div className="each-container">
          <div className="team-lead">
            <Trans i18nKey={"team.team_lead"} />
          </div>
        </div>
      ) : null}
    </div>
  )
}

export default TeamCard
