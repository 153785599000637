import React from "react"
import DefaultAvatar from "../assets/images/group.png"
import UploadIcon from "../assets/images/upload-icon.svg"
import Throbber from "./throbber"

const TeamImageCard = ({
  image,
  id,
  isAddCard,
  editMode,
  selectedFile,
  setSelectedFile,
  loadingMediaUpload,
}) => {
  return (
    <div className="team-box">
      <div className="action-container">
        {loadingMediaUpload ? <Throbber relative alignRight /> : <></>}
      </div>
      <div className="each-container team-image-container">
        <div>
          <img
            className="avatar-box"
            src={
              selectedFile
                ? URL.createObjectURL(selectedFile[0]) // Use selectedFile URL if available
                : image && image.path
                ? image.path // Use image URL if available
                : DefaultAvatar // Fallback to DefaultAvatar
            }
            style={{ height: 120, width: 140, objectFit: "cover" }}
            alt="Avatar"
          />
        </div>
        {editMode || isAddCard ? (
          <div>
            <label htmlFor={`file-input-${id}`} className="upload-icon">
              <img className="upload-icon" src={UploadIcon} alt="Upload" />
            </label>
            <input
              id={`file-input-${id}`}
              type="file"
              accept="image/*"
              onChange={e => setSelectedFile(e.target.files)}
              style={{ display: "none" }}
            />
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  )
}

export default TeamImageCard
