import React, { useState, useEffect } from "react"
import EditIcon from "../assets/images/edit-icon.svg"
import CrossIcon from "../assets/images/cross-icon.svg"
import TickIcon from "../assets/images/tick-icon.svg"
import { useMutation, useQuery } from "@apollo/react-hooks"
import Throbber from "./throbber"
import { ME } from "../gql"
import { UPDATE_TEAM } from "../gql/user"
import TETorch from "./../assets/brands/te/te-torch.png"
import { Trans } from "react-i18next"
import TeamImageCard from "./team-image-card"
import useS3 from "../hooks/useS3"

const TeamDetails = () => {
  const {
    data: userData,
    loading: userDataLoading,
    refetch: RefetchUserData,
  } = useQuery(ME)
  const [editMode, setEditMode] = useState(false)
  const [teamNameVal, setTeamNameVal] = useState("")
  const [updateTeam, { loading: loadingUpdateTeam }] = useMutation(UPDATE_TEAM)
  const [teamImageVal, setTeamImageVal] = useState(null)
  const [selectedFile, setSelectedFile] = useState(null)
  const [s3Response, setS3Response] = useState(null)
  const { uploadToS3, loading: loadingMediaUpload } = useS3()

  const handleAddTeamImage = async () => {
    try {
      if (selectedFile) {
        const file = selectedFile[0]
        const s3UploadResponse = await uploadToS3(file)
        setTeamImageVal(s3UploadResponse)
        setS3Response(s3UploadResponse)
      }
    } catch (e) {
      console.log(e, "ERROR WHILE ADDING IMAGE")
    }
  }

  const handleSubmit = () => {
    updateTeam({
      variables: {
        teamName: teamNameVal,
        // teamValues,
        teamImage: s3Response !== null ? teamImageVal : null,
      },
      update: () => {
        setEditMode(false)
        RefetchUserData()
        setS3Response(null)
      },
    })
  }
  const handleOnSave = () => {
    handleSubmit()
  }
  const handleOnEditPress = () => {
    setEditMode(true)
  }

  const handleOnCancel = () => {
    if (userData && userData.me && userData.me.teamName) {
      setTeamNameVal(userData.me.teamName)
    }

    if (userData && userData.me && userData.me.teamImage) {
      setSelectedFile(null)
      setTeamImageVal(userData.me.teamImage)
    }
    setEditMode(false)
  }

  const handleUpdateData = () => {
    if (selectedFile !== null) {
      handleAddTeamImage()
    } else {
      handleOnSave()
    }
  }

  useEffect(() => {
    if (userData && userData.me) {
      if (userData.me.teamImage && userData.me.teamImage.path) {
        setTeamImageVal(userData.me.teamImage)
      }
      if (userData.me.teamName) {
        setTeamNameVal(userData.me.teamName)
      } else {
        setTeamNameVal("Please add team name")
      }
    }
  }, [userData])

  useEffect(() => {
    if (s3Response) {
      handleOnSave()
    }
  }, [s3Response])

  const loading = userDataLoading || loadingUpdateTeam
  return (
    <div className="team-box-container">
      <div className="flex-center">
        <img src={TETorch} alt="te-torch" className="torch-image" />
        <h1 className="team-details-text big-title">
          <Trans i18nKey="team-details-card.team_title" />{" "}
        </h1>
      </div>
      <div className="action-container">
        {!editMode && userData ? (
          <>
            <img
              className="action-icon"
              onClick={handleOnEditPress}
              src={EditIcon}
              alt="Edit"
            />
          </>
        ) : !loading && userData ? (
          <>
            <img
              className="edit-actions"
              onClick={handleOnCancel}
              src={CrossIcon}
              alt="Close"
            />
            <img
              className="edit-actions"
              onClick={handleUpdateData}
              src={TickIcon}
              alt="Save"
            />
          </>
        ) : loading || !userData ? (
          <Throbber relative alignRight />
        ) : (
          <></>
        )}
      </div>
      <div className="team-img-con">
        <TeamImageCard
          image={teamImageVal}
          isAddCard={editMode}
          editMode={editMode}
          selectedFile={selectedFile}
          setSelectedFile={setSelectedFile}
          loadingMediaUpload={loadingMediaUpload}
        />
      </div>
      <div className="team-details">
        <h3 className="team-details-text">
          <Trans i18nKey="team-details-card.team_name_text" />{" "}
        </h3>
        {!userDataLoading && !editMode ? (
          <h2 className="team-details-text">{teamNameVal}</h2>
        ) : null}
        {!userDataLoading && editMode ? (
          <input
            type="text"
            value={teamNameVal}
            onChange={e => setTeamNameVal(e.target.value)}
          />
        ) : null}
      </div>
      {/* <div className="team-details">
        <h3 className="team-details-text">
          <Trans i18nKey="team-details-card.team_values_text" />{" "}
        </h3>
        {!userDataLoading ? (
          <h2
            className="team-details-text"
            ref={teamValuesRef}
            suppressContentEditableWarning={true}
            contentEditable={editMode}
            dangerouslySetInnerHTML={{
              __html:
                userData && userData.me && userData.me.teamValues
                  ? userData.me.teamValues
                  : "Please add team values",
            }}
          />
        ) : (
          <></>
        )}
      </div> */}
    </div>
  )
}

export default TeamDetails
