import React, { useEffect, useState } from "react"
import Layout from "../components/layout"
import "../styles/components/_team.scss"
import TeamCard from "../components/team-card"
import { useInView } from "react-intersection-observer"
import { GET_TEAMS_MEMBERS } from "../gql/team"
import { ME } from "../gql"
import { useQuery } from "@apollo/react-hooks"
import Throbber from "../components/throbber"
import AddIcon from "../assets/images/add-icon.svg"
import TeamDetails from "../components/team-details-card"
import { Trans, withTranslation } from "react-i18next"

const MEMBER_TYPE_NAME = "Members"
const limit = 20
const Team = ({ location }) => {
  const [showAddForm, setShowAddForm] = useState(false)
  const [skipMembers, setSkipMembers] = useState(0)
  const [userData, setUserData] = useState(null)

  const [ref, loadMoreMembers] = useInView()

  const { data: meData, loading: meDataLoading } = useQuery(ME)

  const { data, loading, fetchMore, refetch } = useQuery(GET_TEAMS_MEMBERS, {
    variables: {
      pagination: {
        skip: 0,
        limit: limit,
      },
    },
    fetchPolicy: "network-only",
  })

  const handleOnRefetch = () => {
    setSkipMembers(0)
    refetch()
  }

  useEffect(() => {
    if (loadMoreMembers && !loading) {
      getMoreTeamMembers()
    }
  }, [loadMoreMembers])

  useEffect(() => {
    if (meData && meData.me) {
      setUserData(meData.me)
    }
  }, [meData, meDataLoading])

  const getMoreTeamMembers = () => {
    fetchMore({
      variables: {
        pagination: {
          skip: skipMembers + limit,
          limit: limit,
        },
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev
        setSkipMembers(skipMembers + limit)
        return {
          getMembers: {
            members: [
              ...prev.getMembers.members,
              ...fetchMoreResult.getMembers.members,
            ],
            __typename: MEMBER_TYPE_NAME,
          },
        }
      },
    })
  }

  const handleOnAddClick = () => {
    setShowAddForm(true)
  }

  const renderAddCardPlaceHolder = () => {
    return (
      <div className="team-box add" onClick={handleOnAddClick}>
        <div className="action-container"></div>
        <div className="each-container">
          <div className="avatar-box">
            <img src={AddIcon} alt="Avatar" />
          </div>
        </div>
        <div className="each-container">
          <p className="name-text">
            <Trans i18nKey={"team.add_member"} />
          </p>
        </div>
      </div>
    )
  }

  const teamMemberData =
    (data && data.getMembers && data.getMembers.members) || []
  return (
    <Layout path={location.pathname}>
      <TeamDetails />
      <div className="team-container">
        {showAddForm ? (
          <TeamCard
            handleOnRefetch={handleOnRefetch}
            isAddCard={showAddForm}
            name=""
            email=""
            onClose={() => setShowAddForm(false)}
          />
        ) : (
          renderAddCardPlaceHolder()
        )}

        {teamMemberData.map(eachMember => {
          const { email, name, image, id } = eachMember
          return (
            <TeamCard
              key={id}
              handleOnRefetch={handleOnRefetch}
              email={email}
              name={name}
              image={image}
              id={id}
              userData={userData}
            />
          )
        })}

        {loading && <Throbber relative />}
        <div ref={ref}></div>
      </div>
    </Layout>
  )
}

export default withTranslation()(Team)
